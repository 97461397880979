.testimonials-custom-bg .swiper-button-next,
.testimonials-custom-bg .swiper-button-prev {
    color: #000 !important;
    background-color: #ffffff !important;
    width: 45px !important;
    height: 45px !important;
    border-radius: 5px;
}
.testimonials-custom-bg .swiper-button-next:after,
.testimonials-custom-bg .swiper-button-prev:after {
    font-size: 15px !important;
}
.testimonials-custom-bg .swiper,
.testimonials-custom-bg .swiper-container {
    width: 75% !important;
    height: 75% !important;
}
.testimonials-custom-bg {
    max-width: 1200px;
}
@media (max-width: 480px) {
    .testimonial-section {
        padding: 64px 0px 64px 0px;
    }
    .cta-section .text h2 {
        text-align: center;
    }
    .cta-section .btn-box {
        text-align: center;
    }
}
