.services-one-testimonials .swiper-pagination-bullet-active {
    background: #d3e6db;
    width: 11px;
    height: 11px;
}
.services-one-testimonials .swiper-pagination-bullet {
    float: right;
    background: #54d08a;
    width: 11px;
    height: 11px;
}
