* {
    font-family: 'Rubik', sans-serif;
}

/** header-top **/
.header-top {
    position: relative;
    background-color: #1b1d21;
    padding: 13.5px 70px;
    display: block;
}
ul {
    margin-bottom: 0rem !important;
    padding-left: 0rem !important;
}
.header-top .info li {
    position: relative;
    display: inline-block;
    float: left;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 40px;
    padding-left: 22px;
}

.header-top .info li:last-child {
    margin: 0px !important;
}

.header-top .info li a {
    display: inline-block;
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}

.header-top .info li a:hover {
}

.header-top .info li i {
    position: absolute;
    left: 0px;
    top: 5px;
}

.header-top .info li::before {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 0.2);
    width: 1px;
    height: 16px;
    top: 4px;
    right: -20px;
}

.header-top .info li:last-child::before {
    display: none;
}

.header-top .social-links li {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 30px;
}

.header-top .social-links li:last-child {
    margin: 0px !important;
}

.header-top .social-links li a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.header-top .social-links li a:hover {
}

.header-top .social-links,
.header-top .sign-box {
    position: relative;
    float: left;
}

.header-top .social-links {
    margin-right: 0px;
}

/* .header-top .social-links:before {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 0.2);
    width: 1px;
    height: 16px;
    right: -30px;
    top: 4px;
} */

/** header-lower **/

.nav_custom {
    height: auto;
    background-color: #fff;
    width: 100% !important;
    box-shadow: 0px 15px 10px -15px #2dbe6c;
}
.sticky {
    position: fixed;
    top: 0;
}

.header-lower {
    position: relative;
}

.main-header .outer-box {
    position: relative;
    padding: 0px 70px;
}

.main-header .outer-box .main-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.main-header .logo-box {
    position: relative;
    padding: 27.5px 0px;
}

.main-header .logo-box .logo {
    max-width: 214px;
}

/* bootom header */
.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 4.5rem;
    padding-left: 0.5rem;
}
.navbar-light .navbar-nav .nav-link {
    position: relative;
    display: block;
    text-align: center;
    font-size: 17px;
    /* line-height: 30px; */
    font-weight: 500;
    opacity: 1;
    color: #2d2929;
    padding: 0px 30px !important;
    /* border-right: 1px solid #e5e7ec; */
    z-index: 1000000000;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.navbar-light .navbar-nav .nav-link a {
    text-decoration: none;

    /* border-right: 1px solid #e5e7ec;
    padding-right: inherit; */
}
.navbar-light .navbar-nav .nav-link a {
    color: #111111;
}
.navbar-light .navbar-nav .nav-link a.active {
    color: #2dbe6c !important;
}
.navbar-light .navbar-nav .nav-link a:hover {
    color: #2dbe6c !important;
}
.navbar-light .navbar-nav .nav-link a:active {
    color: #2dbe6c !important;
}
.dropdown {
    text-decoration: none;
}
.navbar-light .navbar-nav .nav-link:first-child {
    /* border-right: 1px solid #e5e7ec;
    border-left: 1px solid #e5e7ec; */
}
.nav-link:hover {
    color: #2dbe6c !important;
}

.dropdown-menu.show {
    border-top: 3px solid #2dbe6c;
    margin-top: 0px !important;
}
.dropdown-menu[data-bs-popper] {
    margin-top: 0px !important;
}
.dropdown-menu {
    top: 48px;
    display: none;
}

/* #my-a a {
    color: #111111 !important;
}
#my-a a:active {
    color: #2dbe6c !important;
} */

/* .dropdown-item.active,
.dropdown-item:active {
    color: inherit;
    background-color: inherit;
} */
.dropdown-item:hover {
    color: #2dbe6c !important;
}
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    border-top: 3px solid #2dbe6c;
}
/* ////////////////////////////////////////////////////// */

.main-header .outer-box {
    position: relative;
    padding: 0px 70px;
}

.main-header .outer-box .main-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.main-header .logo-box {
    position: relative;
    padding: 0px;
}

.main-header .logo-box .logo {
    padding-top: 0px;
    padding-bottom: 0px;
}

.main-header .logo-box .logo img {
    width: 300px !important;
}

/* my new copy for header */

/** header-lower **/
.main-header .outer-box {
    position: relative;
    padding: 0px 70px;
}

.main-header .outer-box .main-box {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.main-header .logo-box {
    position: relative;
    padding: 0px 0px;
}

.main-header .logo-box .logo {
    max-width: 270px;
}

/** main-menu **/
.navbar-expand-xl {
    padding: 0px !important;
}
.navbar-expand-xl .navbar-nav .nav-link {
    /* padding-left: 30px !important;
    padding-right: 30px !important; */
    padding: 30px 30px !important;
}

.menu-area .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    color: #3786ff;
    display: none;
}

.menu-area .mobile-nav-toggler .icon-bar {
    position: relative;
    height: 2px;
    width: 30px;
    display: block;
    margin-bottom: 5px;
    background-color: #111111;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-header.style-two .menu-area .mobile-nav-toggler .icon-bar {
    background-color: #222;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child {
    margin-bottom: 0px;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #2dbe6c !important;
    background-color: inherit !important;
}
/* offcanvas css */
.offcanvas-custom .offcanvas-footer {
    display: none !important;
}

.navbar-expand-xl .navbar-nav .dropdown-menu {
    /* top: 60px; */
}
hr {
    margin-top: 25px !important;
    padding-top: 25px;
}

/* custom dropdown */
.svg-inline--fa {
    font-size: 15px;
}
.dropdown-toggle::after {
    display: none !important;
}
.fa-phone {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.nav-border-custom {
    border-right: 2px solid #e1dede !important;
    margin-top: 30px;
    margin-bottom: 30px;
}
/* .navbar-light .navbar-nav .nav-link span a {
    color: inherit;
} */
/* html {
    scroll-behavior: smooth;
    scroll-margin-top: 60px;
} */
@media (max-width: 468px) {
    .nav-border-custom {
        display: none !important;
    }
    #nav-dropdown-services {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    #nav-dropdown-home {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    .nav-link {
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }
    .svg-inline--fa {
        float: right;
        padding-right: 15px;
    }
    .main-header .logo-box .logo img {
        width: 75% !important;
    }
    .header-top {
        display: none;
        padding: 13.5px 35px;
    }
    .navbar-light .navbar-nav .nav-link {
        display: none;
    }
    .main-header .outer-box {
        padding: 15px 24px;
    }
    .offcanvas-custom .offcanvas-body {
        padding: 0% !important;
        padding-top: 30px !important;
    }
    .offcanvas-custom .offcanvas-header .btn-close {
        background-color: #ffffff !important;
    }
    .offcanvas-custom .offcanvas-footer {
        display: block !important;
    }
    .offcanvas-custom {
        background-color: #061a3a !important;
        padding-left: 0px !important;
    }
    .offcanvas-custom a {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 24px;
        /* padding: 10px; */
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
    }
    .offcanvas-custom .navbar-nav .nav-link {
        padding-left: 30px;
    }
    .offcanvas-custom .navbar-nav .nav-link:active {
        color: #2dbe6c;
    }
    .offcanvas-custom #my-a a {
        color: #fff !important;
    }
    .nav-item.dropdown .dropdown-menu {
        padding-left: 30px;
    }
    .nav-footer .contact-info {
        padding: 120px 30px 20px 30px;
    }
    .nav-footer .contact-info h4 {
        position: relative;
        font-size: 20px;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .nav-footer .social-links {
        position: relative;
        padding: 0px 25px;
    }

    .nav-footer .social-links li {
        position: relative;
        display: inline-block;
        margin: 0px 10px 10px;
    }
    .offcanvas-custom .dropdown-menu.show {
        padding-left: 30px !important;
        /* color: #000; */
        /* border-top: 1px solid #fff; */
    }
    .offcanvas-custom .dropdown-menu.show span {
        color: inherit !important;
    }
    .dropdown-item {
        color: #000 !important;
    }
    .offcanvas-custom .nav-footer .social-links li a {
        position: relative;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        font-weight: 300;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }
    .nav-footer .contact-info ul li {
        padding-bottom: 3px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
    }
    .nav-footer .contact-info ul li a {
        padding-top: inherit;
        padding-left: 0px !important;
        text-transform: lowercase;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
    }
}
@media (min-width: 469px) and (max-width: 767px) {
    .nav-border-custom {
        display: none !important;
    }
    #nav-dropdown-services {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    #nav-dropdown-home {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    .nav-link {
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }
    .svg-inline--fa {
        float: right;
        padding-right: 15px;
    }
    .main-header .logo-box .logo img {
        width: 75% !important;
    }
    .header-top {
        display: none;
        padding: 13.5px 35px;
    }
    .navbar-light .navbar-nav .nav-link {
        display: none;
    }
    .main-header .outer-box {
        padding: 15px 24px;
    }
    .offcanvas-custom .offcanvas-body {
        padding: 0% !important;
        padding-top: 30px !important;
    }
    .offcanvas-custom .offcanvas-header .btn-close {
        background-color: #ffffff !important;
    }
    .offcanvas-custom .offcanvas-footer {
        display: block !important;
    }
    .offcanvas-custom {
        background-color: #061a3a !important;
        padding-left: 0px !important;
    }
    .offcanvas-custom a {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 24px;
        /* padding: 10px; */
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
    }
    .offcanvas-custom .navbar-nav .nav-link {
        padding-left: 30px;
    }
    .offcanvas-custom .navbar-nav .nav-link:active {
        color: #2dbe6c;
    }
    .offcanvas-custom #my-a a {
        color: #fff !important;
    }
    .nav-item.dropdown .dropdown-menu {
        padding-left: 30px;
    }
    .nav-footer .contact-info {
        padding: 120px 30px 20px 30px;
    }
    .nav-footer .contact-info h4 {
        position: relative;
        font-size: 20px;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .nav-footer .social-links {
        position: relative;
        padding: 0px 25px;
    }

    .nav-footer .social-links li {
        position: relative;
        display: inline-block;
        margin: 0px 10px 10px;
    }
    .offcanvas-custom .dropdown-menu.show {
        padding-left: 30px !important;
        /* color: #000; */
        /* border-top: 1px solid #fff; */
    }
    .offcanvas-custom .dropdown-menu.show span {
        color: inherit !important;
    }
    .dropdown-item {
        color: #000 !important;
    }
    .offcanvas-custom .nav-footer .social-links li a {
        position: relative;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        font-weight: 300;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }
    .nav-footer .contact-info ul li {
        padding-bottom: 3px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
    }
    .nav-footer .contact-info ul li a {
        padding-top: inherit;
        padding-left: 0px !important;
        text-transform: lowercase;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .nav-border-custom {
        display: none !important;
    }
    #nav-dropdown-services {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    #nav-dropdown-home {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    .nav-link {
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }
    .svg-inline--fa {
        float: right;
        padding-right: 15px;
    }
    .main-header .logo-box .logo img {
        width: 75% !important;
    }
    .header-top {
        display: none;
        padding: 13.5px 35px;
    }
    .navbar-light .navbar-nav .nav-link {
        display: none;
    }
    .main-header .outer-box {
        padding: 15px 24px;
    }
    .offcanvas-custom .offcanvas-body {
        padding: 0% !important;
        padding-top: 30px !important;
    }
    .offcanvas-custom .offcanvas-header .btn-close {
        background-color: #ffffff !important;
    }
    .offcanvas-custom .offcanvas-footer {
        display: block !important;
    }
    .offcanvas-custom {
        background-color: #061a3a !important;
        padding-left: 0px !important;
    }
    .offcanvas-custom a {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 24px;
        /* padding: 10px; */
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
    }
    .offcanvas-custom .navbar-nav .nav-link {
        padding-left: 30px;
    }
    .offcanvas-custom .navbar-nav .nav-link:active {
        color: #2dbe6c;
    }
    .offcanvas-custom #my-a a {
        color: #fff !important;
    }
    .nav-item.dropdown .dropdown-menu {
        padding-left: 30px;
    }
    .nav-footer .contact-info {
        padding: 120px 30px 20px 30px;
    }
    .nav-footer .contact-info h4 {
        position: relative;
        font-size: 20px;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .nav-footer .social-links {
        position: relative;
        padding: 0px 25px;
    }

    .nav-footer .social-links li {
        position: relative;
        display: inline-block;
        margin: 0px 10px 10px;
    }
    .offcanvas-custom .dropdown-menu.show {
        padding-left: 30px !important;
        /* color: #000; */
        /* border-top: 1px solid #fff; */
    }
    .offcanvas-custom .dropdown-menu.show span {
        color: inherit !important;
    }
    .dropdown-item {
        color: #000 !important;
    }
    .offcanvas-custom .nav-footer .social-links li a {
        position: relative;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        font-weight: 300;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }
    .nav-footer .contact-info ul li {
        padding-bottom: 3px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
    }
    .nav-footer .contact-info ul li a {
        padding-top: inherit;
        padding-left: 0px !important;
        text-transform: lowercase;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 13px !important;
        padding-right: 13px !important;
    }
    .header-top {
        position: relative;
        background-color: #1b1d21;
        padding: 13px 15px;
    }
    .main-header .outer-box .main-box {
        flex-wrap: nowrap;
    }
    .navbar-light .navbar-nav .nav-link {
        font-size: 16px;
    }
    .main-header .logo-box .logo img {
        width: 75% !important;
    }
    .main-header .outer-box {
        padding: 0px 15px;
    }
}
@media (min-width: 1201px) and (max-width: 1500px) {
    .main-header .logo-box .logo img {
        width: 85% !important;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .header-top {
        position: relative;
        background-color: #1b1d21;
        padding: 13px 15px;
    }
    .main-header .outer-box .main-box {
        flex-wrap: nowrap;
    }
    .navbar-light .navbar-nav .nav-link {
        font-size: 17px;
    }
    .main-header .outer-box {
        padding: 0px 15px;
    }
}
