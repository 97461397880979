.about-custom {
    padding-top: 30px;
    height: 560px;
    padding-left: 22px;
    padding-right: 22px;
}
.about-custom .swiper-pagination-bullet-active {
    background: #d3e6db;
    width: 10px;
    height: 10px;
}
.about-custom .swiper-pagination-bullet {
    background: #2dbe6c;
    width: 10px;
    height: 10px;
}
/** testimonial-style-four **/

.testimonial-style-four {
    position: relative;
}

.testimonial-style-four .sec-title h2 {
    margin-bottom: 21px;
}

.testimonial-block-three .inner-box {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 58px 40px 52px 40px;
    box-shadow: 0 10px 30px 0px rgb(0 0 0 / 10%);
}

.testimonial-block-three .inner-box .icon-box {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: #f2b241;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 29px;
}

.testimonial-block-three .inner-box h4 {
    display: block;
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 19px;
}

.testimonial-block-three .inner-box h5 {
    display: block;
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 0px;
}

.testimonial-block-three .inner-box .designation {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 22px;
    margin: 0px;
}

@media (max-width: 468px) {
    .about-custom {
        height: 610px;
        padding-left: 11px;
        padding-right: 11px;
    }
}
@media (min-width: 469px) and (max-width: 575px) {
    .about-custom {
        width: 100%;
        height: 500px;
        padding-left: 11px;
        padding-right: 11px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .about-custom {
        width: 100%;
        height: 500px;
        padding-left: 11px;
        padding-right: 11px;
    }
}
