.accordion-button:not(.collapsed) {
    color: #2dbe6c !important;
    background-color: #fff !important;
    box-shadow: inset 0 calc(4 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color) !important;
}
.accordion {
    --bs-accordion-border-color: #2dbe6c !important;
    --bs-accordion-active-color: #2dbe6c !important;
    --bs-accordion-btn-focus-border-color: #2dbe6c !important;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(45, 190, 108, 0.55) !important;
}
.accordion-button:focus {
    border-color: #2dbe6c !important;
}
.accordion-item {
    margin-bottom: 1rem;
}
.accordion-header {
    border-bottom: 1px solid #2dbe6c !important;
}
.answer-p {
    text-align: left;
}
