.deal-custum {
    max-width: 1350px;
}
.deal-custom .auto-container {
    max-width: 1200px;
    padding: 0px 0px;
    margin: 0 auto;
}
.deal-custom .swiper-button-next,
.deal-custom .swiper-button-prev {
    color: white !important;
    background-color: #2dbe6c !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 5px;
}
.deal-custom .swiper-button-next:after,
.deal-custom .swiper-button-prev:after {
    font-size: 20px !important;
}

.deal-custom .swiper,
.swiper-container {
    width: 100% !important;
    height: 447px !important;
}
.deal-custum-img .deal-img {
    border-radius: 10px;
}

/* second swiper css */
.deal-inner-swiper .swiper-pagination-bullet-active {
    background: #d3e6db;
    width: 10px;
    height: 10px;
}
.deal-inner-swiper .swiper-pagination-bullet {
    background: #2dbe6c;
    width: 10px;
    height: 10px;
}
.deal-inner-swiper img {
    border-radius: 10px;
}
.deal-custom .deals-block .deals-block-one {
    width: auto;
    height: 400px !important;
}

@media (max-width: 468px) {
    .deal-custom .swiper,
    .swiper-container {
        width: 100% !important;
        height: 100% !important;
    }
    .deal-custom .swiper-button-next,
    .deal-custom .swiper-button-prev {
        color: white !important;
        background-color: #2dbe6c !important;
        width: 45px !important;
        height: 45px !important;
        border-radius: 5px;
    }
}
@media (min-width: 469px) and (max-width: 575px) {
    .deal-custom .swiper,
    .swiper-container {
        width: 100% !important;
        height: 100% !important;
    }
    .deal-custom .swiper-button-next,
    .deal-custom .swiper-button-prev {
        color: white !important;
        background-color: #2dbe6c !important;
        width: 45px !important;
        height: 45px !important;
        border-radius: 5px;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .deal-custom .swiper,
    .swiper-container {
        width: 100% !important;
        /* height: 100% !important; */
    }
    .deal-custom .swiper-button-next,
    .deal-custom .swiper-button-prev {
        color: white !important;
        background-color: #2dbe6c !important;
        width: 45px !important;
        height: 45px !important;
        border-radius: 5px;
    }
}
@media (min-width: 769px) and (max-width: 991px) {
    .deal-custom .swiper,
    .swiper-container {
        width: 100% !important;
        /* height: 100% !important; */
    }
    .deal-custom .swiper-button-next,
    .deal-custom .swiper-button-prev {
        color: white !important;
        background-color: #2dbe6c !important;
        width: 45px !important;
        height: 45px !important;
        border-radius: 5px;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .deal-custom .swiper,
    .swiper-container {
        width: 100% !important;
        /* height: 100% !important; */
    }
    .deal-custom .swiper-button-next,
    .deal-custom .swiper-button-prev {
        color: white !important;
        background-color: #2dbe6c !important;
        width: 45px !important;
        height: 45px !important;
        border-radius: 5px;
    }
}
