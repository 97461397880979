.copyright {
    text-align: center !important;
    margin-top: 38px;
}

.partner.sec-pad {
    padding: 35px 0px 35px 0px;
}
.partner-custom .logo img {
    width: auto;
    height: 75px;
}
.partner .inner-column {
    margin: auto;
}
.partner .sec-title {
    text-align: left;
    margin-bottom: 0px;
}
.partner .sec-title h2 {
    font-size: 35px;
    line-height: 46px;
    margin-bottom: 0px;
}
.ml-custom {
    margin-left: 70px;
}
@media (max-width: 468px) {
    .partner .sec-title h2 {
        margin-bottom: 35px;
    }
}
@media (min-width: 469px) and (max-width: 575px) {
    .partner .sec-title h2 {
        margin-bottom: 35px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .partner .sec-title h2 {
        margin-bottom: 35px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .partner .sec-title h2 {
        margin-bottom: 35px;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
}
