.bannertwo-swiper {
    position: relative;
}
.bannertwo-swiper .banner-style-two {
    position: relative;
}
.bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 69px 10px;
    display: block;
}
.bannertwo-swiper span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #2dbe6c;
    width: 11px;
    height: 11px;
    opacity: 1;
}
.bannertwo-swiper .swiper-pagination-bullet {
    background: #d3e6db;
    width: 11px;
    height: 11px;
    opacity: 1;
}

.bannertwo-swiper.swiper,
.bannertwo-swiper .swiper-container {
    height: 650px !important;
}

.bannertwo-swiper .slide-item-my {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 250px 0px 300px 0px;
}

.bannertwo-swiper .slide-item-my:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100% !important;
    background: #1d2534;
    top: 0px;
    right: 0px;
    z-index: 1;
    opacity: 0.5;
}

.bannertwo-swiper .slide-item-my:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100% !important;
    background: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%);
    bottom: 0px;
    right: 0px;
    z-index: 1;
}

.bannertwo-swiper .swiper-slide-active .slide-item-my .image-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1);
    transform: scale(1) !important;
    -webkit-transition: all 6000ms linear;
    -moz-transition: all 6000ms linear;
    -ms-transition: all 6000ms linear;
    -o-transition: all 6000ms linear;
    transition: all 6000ms linear;
}

.bannertwo-swiper .slide-item-my .image-layer {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15) !important;
}

.bannertwo-swiper .content-box {
    position: relative;
    display: block;
    z-index: 5;
}

.bannertwo-swiper .content-box h2 {
    position: relative;
    display: block;
    color: #fff;
    font-size: 55px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 22px;
    opacity: 0;
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: all 2500ms ease;
    -moz-transition: all 2500ms ease;
    -ms-transition: all 2500ms ease;
    -o-transition: all 2500ms ease;
    transition: all 2500ms ease;
}

.bannertwo-swiper .swiper-slide-active .content-box h2 {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 1000ms;
    -moz-transition-delay: 1000ms;
    -ms-transition-delay: 1000ms;
    -o-transition-delay: 1000ms;
    transition-delay: 1000ms;
}

.bannertwo-swiper .content-box p {
    position: relative;
    display: block;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    opacity: 0;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: all 2500ms ease;
    -moz-transition: all 2500ms ease;
    -ms-transition: all 2500ms ease;
    -o-transition: all 2500ms ease;
    transition: all 2500ms ease;
}

.bannertwo-swiper .swiper-slide-active .content-box p {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 1000ms;
    -moz-transition-delay: 1000ms;
    -ms-transition-delay: 1000ms;
    -o-transition-delay: 1000ms;
    transition-delay: 1000ms;
}
/* .swiper-backface-hidden .swiper-slide {
    overflow: hidden !important;
} */
@media (max-width: 468px) {
    .bannertwo-swiper.swiper,
    .bannertwo-swiper .swiper-container {
        height: 500px !important;
    }
    .bannertwo-swiper .slide-item-my {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 150px 0px 150px 0px;
    }
    .bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 10px 0px;
        display: block;
    }
    .bannertwo-swiper .content-box h2 {
        /* text-align: left; */
        font-size: 35px;
        line-height: 50px;
    }
}
@media (min-width: 469px) and (max-width: 575px) {
    .bannertwo-swiper.swiper,
    .bannertwo-swiper .swiper-container {
        height: 490px !important;
    }
    .bannertwo-swiper .slide-item-my {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 160px 0px 160px 0px;
    }
    .bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 10px 0px;
        display: block;
    }
    .bannertwo-swiper .content-box h2 {
        /* text-align: left; */
        font-size: 38px;
        line-height: 50px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .bannertwo-swiper.swiper,
    .bannertwo-swiper .swiper-container {
        height: 490px !important;
    }
    .bannertwo-swiper .slide-item-my {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 160px 0px 160px 0px;
    }
    .bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 10px 0px;
        display: block;
    }
    .bannertwo-swiper .content-box h2 {
        font-size: 45px;
        line-height: 50px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .bannertwo-swiper.swiper,
    .bannertwo-swiper .swiper-container {
        height: 487px !important;
    }
    .bannertwo-swiper .slide-item-my {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 160px 0px 160px 0px;
    }
    .bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 10px 0px;
        display: block;
    }
    .bannertwo-swiper .content-box h2 {
        font-size: 50px;
        line-height: 50px;
    }
}
@media (min-width: 992px) and (max-width: 1024px) {
    .bannertwo-swiper.swiper,
    .bannertwo-swiper .swiper-container {
        height: 487px !important;
    }
    .bannertwo-swiper .slide-item-my {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 160px 0px 160px 0px;
    }
    .bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 10px 0px;
        display: block;
    }
    .bannertwo-swiper .content-box h2 {
        font-size: 50px;
        line-height: 50px;
    }
}
@media (min-width: 1025px) and (max-width: 1400px) {
    .bannertwo-swiper.swiper,
    .bannertwo-swiper .swiper-container {
        height: 487px !important;
    }
    .bannertwo-swiper .slide-item-my {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 160px 0px 160px 0px;
    }
    .bannertwo-swiper .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: var(--swiper-pagination-bullet-vertical-gap, 15px) 10px 0px;
        display: block;
    }
    .bannertwo-swiper .content-box h2 {
        font-size: 50px;
        line-height: 50px;
    }
}
