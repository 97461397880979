.blog-details-content .news-block-one .inner-box .lower-content .text blockquote ol li {
    /* display: list-item; */
    color: #2dbe6c;
    list-style: decimal;
    font-size: 17px;
    line-height: 30px;
    padding-right: 1rem;
    /* list-style-position: inside; */
}
.blog-details-content .news-block-one .inner-box .lower-content .text blockquote ol {
    margin-bottom: 0px;
}
