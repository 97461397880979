.info-block {
    height: 370px;
}
/** contact-info-section **/

.contact-info-section {
    position: relative;
}

.info-block-one .inner-box {
    position: relative;
    display: block;
    background-color: #ffffff;
    padding: 96px 55px 65px 55px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 30px 0px rgb(0 0 0 / 10%);
    transition: all 500ms ease;
}

.info-block-one .inner-box:hover {
    transform: translateY(-10px);
}

.info-block-one .inner-box .icon-box {
    position: relative;
    display: inline-block;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 26px;
    color: #f2b241;
    z-index: 1;
}

/* .info-block:nth-child(2) .info-block-one .inner-box .icon-box {
}

.info-block:last-child .info-block-one .inner-box .icon-box {
    color: #f94c4c;
} */

.info-block-one .inner-box .icon-box:before {
    position: absolute;
    content: '';
    background-color: #f2b241;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    top: -24px;
    right: -33px;
    opacity: 0.2;
    z-index: -1;
    transition: all 500ms ease;
}
.info-block-one .inner-box .icon-box.two {
    position: relative;
    display: inline-block;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 26px;
    color: #2dbe6c;
    z-index: 1;
}
.info-block-one .inner-box .icon-box.two:before {
    position: absolute;
    content: '';
    background-color: #2dbe6c;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    top: -24px;
    right: -33px;
    opacity: 0.2;
    z-index: -1;
    transition: all 500ms ease;
}

.info-block-one .inner-box .icon-box.three {
    position: relative;
    display: inline-block;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 26px;
    color: #f94c4c;
    z-index: 1;
}
.info-block-one .inner-box .icon-box.three:before {
    position: absolute;
    content: '';
    background-color: #f94c4c;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    top: -24px;
    right: -33px;
    opacity: 0.2;
    z-index: -1;
    transition: all 500ms ease;
}

.info-block:nth-child(2) .info-block-one .inner-box .icon-box:before {
}
.info-block-one .inner-box:hover .icon-box:before {
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0.05;
}

.info-block:nth-child(2) .info-block-one .inner-box .icon-box:before {
}

.info-block:last-child .info-block-one .inner-box .icon-box:before {
    background-color: #f94c4c;
}

.info-block-one .inner-box h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 21px;
    transition: all 500ms ease;
}

.info-block-one .inner-box p {
    font-size: 16px;
    line-height: 26px;
    color: #a0a1a8;
}

.info-block-one .inner-box p a {
    color: #a0a1a8;
    text-decoration: none;
}

.info-block-one .inner-box p a:hover {
}

.contact-section .google-map-area {
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
}

.contact-section .google-map-area #contact-google-map {
    width: 100%;
    height: 540px;
}
