.gallary-swiper {
    padding-top: 30px;
    height: 450px;
    padding-left: 22px;
    padding-right: 22px;
}
.gallary-swiper .swiper-pagination-bullet-active {
    background: #d3e6db;
    width: 10px;
    height: 10px;
}
.gallary-swiper .swiper-pagination-bullet {
    background: #2dbe6c;
    width: 10px;
    height: 10px;
}
@media (max-width: 468px) {
    .gallary-swiper {
        height: 450px;
    }
}
@media (min-width: 469px) and (max-width: 575px) {
    .gallary-swiper {
        width: 100%;
        height: 587px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .gallary-swiper {
        width: 100%;
        height: 618px;
    }
}
